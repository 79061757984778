/* eslint-disable no-unused-vars */
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // For generating unique identifiers
import ResultsModal from './ResultsModal';
import ThemeDisplay from './ThemeDisplay';
import MovieQuiz from './MovieQuiz';
import Toolbar from './Toolbar';
import LoadingScreen from './LoadingScreen';
import UpdateBanner from './UpdateBanner';
import './App.css';

function App() {
  const { DateTime } = require("luxon");
  const formattedDate = () => {
    const now = DateTime.utc();
    return now.toFormat('MM/dd/yyyy');
  };

  const [quiz, setQuiz] = useState(null);
  const [stats, setStats] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [questionStatuses, setQuestionStatuses] = useState({
    easy: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟩'], placeholder: "Enter your guess here" },
    medium: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟦'], placeholder: "Enter your guess here" },
    hard: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟪'], placeholder: "Enter your guess here" },
  });
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [results, setResults] = useState('');
  const [error, setError] = useState(null);
  const [mistakesMade, setMistakesMade] = useState(0);
  const [answers, setAnswers] = useState(0);
  const [hintsUsed, setHintsUsed] = useState(0);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [selectedDate, setSelectedDate] = useState(formattedDate());
  const [completedQuizDates, setCompletedQuizDates] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [quizInProgress, setQuizInProgress] = useState(null);
  const [resultsShared, setResultsShared] = useState(false); 

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'https://us-central1-the-emoji-quiz.cloudfunctions.net/api';
  //const localEndpoint = 'http://localhost:5002/the-emoji-quiz/us-central1/api';

  const quizId = selectedDate;

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  let userId = Cookies.get('userId');
  if (!userId) {
    userId = uuidv4();
    Cookies.set('userId', userId, { expires: 365 }); // Set cookie to expire in 1 year
  }

  const areAllAnswersDone = () => {
    return Object.values(questionStatuses).every(status => status.status !== 'unanswered');
  };

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
  }, [isDarkMode]);

  useEffect(() => {
    async function fetchData() {
      if (areAllAnswersDone() && !resultsShared) {  // Check if all answers are done and results not yet shared
        const resultsString = await getResultsEmojiString();
        await new Promise(resolve => setTimeout(resolve, 200));
        setResults(resultsString);
        await new Promise(resolve => setTimeout(resolve, 200));
        handleShareResults();
        setResultsShared(true);  // Set to true after sharing results
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionStatuses, resultsShared]);


  useEffect(() => {
    if (!selectedDate) return;  // Don't make the API call if no date is selected
    fetch(`${apiEndpoint}/daily-quiz?date=${selectedDate}`)
      .then(async response => {
        setLoading(true);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setQuiz(data);
        setError(null);
        setSelectedDate(data.date)
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching quiz data:', error);
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);  // Set the selected date and trigger the useEffect
  };

  const fetchHistoricalQuizzes = async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/get-completed-quizzes?userId=${userId}`);
      setCompletedQuizDates(response.data.completedQuizDates);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Fetch user stats
  const fetchStats = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiEndpoint}/stats?userId=${userId}`);
      setStats(response.data);
      setShowHowToPlay(response?.data?.gamesPlayed === 0)
      setIsDarkMode(response?.data?.darkMode)
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchStats();
    fetchHistoricalQuizzes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
  }, [loading]);  // This will trigger when `loading` changes
  

  useEffect(() => {
    setLoading(true);
    const resetProgress = () => {
      return new Promise((resolve) => {
        setQuestionStatuses({
          easy: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟩'], placeholder: "Enter your guess here" },
          medium: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟦'], placeholder: "Enter your guess here" },
          hard: { status: 'unanswered', hints: { year: false, genre: false, director: false, mainActor: false }, mistakes: 0, graded: false, emoji: ['🟪'], placeholder: "Enter your guess here" },
        });
        setResultsShared(false); 
        // Use a small timeout to ensure UI updates fully before resolving
        setTimeout(() => {
          resolve();  // Resolve after resetting progress
        }, 100);  // 100ms delay
      });
    };

    const fetchProgress = async (userId, selectedDate) => {
      await resetProgress();
      const controller = new AbortController();  // Create an abort controller
      // Check if we're currently loading or fetching a quiz already
      if (loading || quizInProgress === selectedDate) {
        return;  // If already fetching or same quiz is selected, exit
      }

      try {
        // Step 1: Set loading state to true and reset current quiz in progress
        setQuizInProgress(selectedDate);
        // Step 1: Reset progress first
        // Fetch the new progress
        const response = await axios.get(`${apiEndpoint}/progress?userId=${userId}&quizId=${selectedDate}`,{
          signal: controller.signal,  // Pass the abort signal
        });

        if (response.data) {
          setQuestionStatuses(response.data.progress);  // Set new progress once fetched
        } else {
          await resetProgress();
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching progress:", error.message);
        }
      } finally {
        setLoading(false);
      }
    };
  
    if (selectedDate) {
      fetchProgress(userId, selectedDate);  // Fetch progress every time selectedDate changes
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);  // Trigger useEffect when selectedDate changes
  
  
  const updateStats = async (gamesPlayed, gamesWon, hintsUsed, mistakesMade, darkMode) => {
    const userId = Cookies.get('userId');
    try {
      const response = await axios.post(`${apiEndpoint}/update-stats`, {
        userId,
        gamesPlayed,
        gamesWon,
        hintsUsed,
        mistakesMade,
        darkMode,
      });
      setStats(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateProgress = async (progress, date) => {
    try {
      await axios.post(`${apiEndpoint}/update-progress`, { userId, progress, quizId });
      await new Promise(resolve => setTimeout(resolve, 200));
      setQuestionStatuses(progress);
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateFeedback = async (likes, dislikes, date) => {
    try {
      await axios.post(`${apiEndpoint}/feedback`, { likes, dislikes, date });
      await new Promise(resolve => setTimeout(resolve, 200));
    } catch (error) {
      console.error(error.message);
    }
  };

  const checkAnswer = async (guess, level) => {
    const updatedStatuses = { ...questionStatuses };

    // Reveal question 
    if (updatedStatuses[level].status === 'revealed') {
      updatedStatuses[level].status = 'incorrect';
      updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '❌']
      await new Promise(resolve => setTimeout(resolve, 200));
    }

    // Actually Answer Question
    else {
      const levenshteinAnswer = checkLevenshteinAnswer(guess, level, 3);
      if (levenshteinAnswer && updatedStatuses[level].status === 'unanswered') {
        setAnswers(answers + 1);
        updatedStatuses[level].status = 'correct';
        updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '✅']
        await new Promise(resolve => setTimeout(resolve, 200));
      } else {
        if (checkLevenshteinAnswer(guess, level, 6)) {
          updatedStatuses[level].placeholder = "So close! Try again!"
        } else {
          updatedStatuses[level].placeholder = "Try again"
        }
        updatedStatuses[level].mistakes = updatedStatuses[level].mistakes + 1
        updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '⚪️']
        await new Promise(resolve => setTimeout(resolve, 200));
        if (questionStatuses[level].mistakes >= 4) {
          updatedStatuses[level].status = 'incorrect';
          updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '❌']
          await new Promise(resolve => setTimeout(resolve, 200));
        }
      }
    }
    setQuestionStatuses(updatedStatuses);
    await updateProgress(updatedStatuses, selectedDate);
    fetchHistoricalQuizzes();
  };

  const levenshtein = require("fast-levenshtein");
  // Function to check if the guess is correct
  const checkLevenshteinAnswer = (guess, level, threshold) => {
    const userInputLower = guess?.toLowerCase();
    const correctTitles = quiz[level].answers || null;
    const correctTitlesLower = correctTitles?.map((title) => title.toLowerCase()) || [];
    // Check for exact match
    if (correctTitlesLower?.includes(userInputLower)) {
      return true;
    }
    // Check for close matches using Levenshtein Distance
    for (const title of correctTitlesLower) {
      const distance = levenshtein.get(userInputLower, title);
      if (distance <= threshold) {
        return true;
      }
    }
    return false;
  };

  const revealHint = (level, hintType) => {
    const updatedStatuses = { ...questionStatuses };
    if (updatedStatuses[level] && updatedStatuses[level].hints) {
      updatedStatuses[level].hints[hintType] = true;
      updatedStatuses[level].emoji = [...updatedStatuses[level].emoji, '💡']
      setQuestionStatuses(updatedStatuses);
      updateProgress(questionStatuses, selectedDate);
    }
  };

  const getResultsEmojiString = async () => {
    return `${questionStatuses["easy"].emoji.join('')}\n${questionStatuses["medium"].emoji.join('')}\n${questionStatuses["hard"].emoji.join('')}`;
  };

  const handleShareResults = async () => {
    const mistakes = questionStatuses["easy"].mistakes + questionStatuses["medium"].mistakes + questionStatuses["hard"].mistakes
    setMistakesMade(mistakes)

    const gamesPlayed = 1; // Assuming one game played per session
    const gamesWon = (answers === 3 ? 1 : 0)

    const hintsUsed = (questionStatuses["easy"].hints.year ? 1 : 0) +
      (questionStatuses["easy"].hints.genre ? 1 : 0) +
      (questionStatuses["easy"].hints.director ? 1 : 0) +
      (questionStatuses["easy"].hints.mainActor ? 1 : 0) +
      (questionStatuses["medium"].hints.year ? 1 : 0) +
      (questionStatuses["medium"].hints.genre ? 1 : 0) +
      (questionStatuses["medium"].hints.director ? 1 : 0) +
      (questionStatuses["medium"].hints.mainActor ? 1 : 0) +
      (questionStatuses["hard"].hints.year ? 1 : 0) +
      (questionStatuses["hard"].hints.genre ? 1 : 0) +
      (questionStatuses["hard"].hints.director ? 1 : 0) +
      (questionStatuses["hard"].hints.mainActor ? 1 : 0);
    setHintsUsed(hintsUsed);

    questionStatuses["easy"].graded = true;
    questionStatuses["medium"].graded = true;
    questionStatuses["hard"].graded = true;

    await updateStats(gamesPlayed, gamesWon, hintsUsed, mistakes, isDarkMode);
    await updateProgress(questionStatuses, selectedDate);
  };

  const shareResults = `m🙂jie\n"${quiz?.theme}"\n${results}\nmojiequiz.com`
  const copyResultsToClipboard = () => {
    navigator.clipboard.writeText(shareResults)
      .then(() => {
      })
      .catch(err => {
        console.error('Failed to copy results:', err);
      });
  };

  return (
    <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
      <UpdateBanner/>
      <div className='App'>
        <>
          <div>
            <Toolbar isDarkMode={isDarkMode} onToggle={toggleTheme} stats={stats} showHowToPlay={showHowToPlay} handleDateChange={handleDateChange} completedQuizDates={completedQuizDates} date={selectedDate} />
          </div>
          <br />
          <header className="App-header">
            <h1>m🙂jie</h1>
            {error && <p>Error: {error.message}</p>}
            {quiz && !loading ? (
              <>
                <ThemeDisplay theme={quiz.theme} isDarkMode={isDarkMode} date={selectedDate} />
                <div className="movie-levels">
                  {['easy', 'medium', 'hard'].map(level => (
                    <MovieQuiz
                      key={level}
                      level={level}
                      movie={quiz[level]}
                      onGuess={checkAnswer}
                      onRevealHint={revealHint}
                      questionStatus={questionStatuses[level]}
                      isDarkMode={isDarkMode}
                    />
                  ))}
                </div>
              </>
            ) : (
              <LoadingScreen loading={loading} />
            )}
          </header>
          <button className="view-results-button" onClick={() => setIsModalOpen(true)} hidden={!areAllAnswersDone()}>View Results</button>
          <ResultsModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            results={results}
            onCopy={copyResultsToClipboard}
            theme={quiz ? quiz.theme : null}
            date={quiz ? quiz.date : selectedDate}
            isDarkMode={isDarkMode}
            mistakes={mistakesMade}
            answers={answers}
            hints={hintsUsed}
            updateFeedback={updateFeedback}
          />
        </>
      </div>
    </div>
  );
}

export default App;
